.project-info{
    display: flex;
    gap: 10px;
    align-items: center;
}

.author-inner-image img{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 100%;
}

.hero-image-container img{
    width: 100%;
    height: 20%;
    object-fit: cover;
}

.parent-layout-container{
    padding: 20px 40px 40px 40px;
}

main.layout-body{
    padding: 80px 0px 20px 0px;
}

.project-info-conatiner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.author-wrapper-main{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.published-container h5{
    font-weight: 400;
}

.project-name h1{
    font-size: 105px;
    font-family: 'poppins',sans-serif;
    font-weight: 500;
    text-align: center;
}

.hero-image-container{
    margin-top: 80px;
}

.project-description{
    margin: 60px 0px;
    text-align: center;
    font-size: 42px;
    font-weight: 300;
}

.author-inner-title h4, .project-info h4{
    font-weight: 500;
}


.scrollable_container{
    width: 100%;
    height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
    object-fit: cover   ;
}

.scrollable_image{
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

.scrollable_container::-webkit-scrollbar{
    width: 04px;
}

.scrollable_container::-webkit-scrollbar-thumb{
    background-color: #f05928;
}

.scrollable_container::-webkit-scrollbar-track{
    background-color: white;
}

.scrollable_container::-webkit-scrollbar-thumb:hover{
    background-color: #727272;
}

.main_wrapper_card{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
}

.related_product .related-contaier-heading{
    margin: 60px 20px 20px 0px;
    font-weight: 400;
    font-size: 32px;
    text-transform: capitalize;
}

.live-preview-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 80px;
}

.live-preview-content h3{
    font-size: 32px;
    font-weight: 400;
}

.content-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.content-wrapper span{
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-wrapper span::before{
    content: "";
border: 1px solid white;
background-color: white;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 100px;
}

@media screen and (max-width:765px){
    .project-name h1{
        font-size: 42px;
    }

   header.card-inner-wrapper{
    display: none;
   }

   main.layout-body{
    padding: 20px 0px;
   }

   .project-description{
    font-size: 18px;
    margin: 40px 0px;
   }

   .main_wrapper_card{
    grid-template-columns: repeat(1,1fr);
   }

   .hero-image-container{
    margin-top: 20px;
   }

   .hero-image-container img{
    height: 300px;
   }

   .live-preview-content h3{
    font-size: 24px;
    text-align: center;
}

.content-wrapper{
    flex-direction: column;
}

.scrollable_container{
    height: 500px;
}
}