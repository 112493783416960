.carousel_wrapper {
  position: relative;
  width: 100%;
  max-width: 600px; /* Adjust based on your needs */
  overflow: hidden;
  margin: auto;
}

.carousel_container {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  justify-content: center;
}

.carousel_container_mobile{
  display: flex;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.carousel_slide {
  min-width: 100%;
  box-sizing: border-box;
}

.carousel_image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.carousel_button {
  all: unset;
  background-color: #f05928;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 15px;
  margin-top: 10px;
}

.carousel_button_container{
  display: flex;
  gap: 10px;
}

.carousel_button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}