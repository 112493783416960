#contact_project_section{
    padding: 150px 20px;
}

.contact_project_conatiner h2{
    font-size: 3rem;
    text-align: left;
    font-weight: 400;
}

.contact_project_conatiner h2 span a {
    position: relative;
  }

.contact_project_conatiner h2 span a{
    color: black;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
}

.contact_project_conatiner h2 span a:hover:before {
    content: "";
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 0;
    border-bottom: 2px solid #f48864;
    animation: border_anim 0.3s linear forwards;
  }
  
  .contact_project_conatiner h2 span a:hover {
    transition: border 0.8s ease-in-out;
  }
  
  @keyframes border_anim {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

.main-wrapper-footer{
    padding: 60px 60px 0px 60px;
    background-color: black;
}

.footer_heading h2{
    margin: 40px 0px;
    font-weight: 400;
    color: white;
    font-size: 4rem;
}

.email_heading_footer h3{
    color: white;
    font-weight: 400;
}

.business_email_wrapp a{
    all: unset;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.email_heading_footer h3{
    font-size: 24px;
    font-weight: 500;
}

.form_input_feild_wrapper{
    display: flex;
    flex-direction: column;
    max-width: 200px;
}

.email_input_wrapper input{
    all: unset;
    color: white;
}

.email_input_wrapper input::placeholder{
color: white;
}

.email_input_wrapper{
    border-bottom: 1px solid white;
}

.email_input_wrapper button {
    all: unset;
    cursor: pointer;
}

.business_email_wrapp{
    margin: 40px 0px;
}

.email_input_wrapper button img{
    width: 10px;
}

.email_input_wrapper{
    padding: 10px 0px;
}

.footer_company_name span img{
    padding: 60px 0px;
}

.email_input_wrapper input:focus{
    color: white;
}

.form_link_main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

.footer_quick_link ul li{
    list-style: none;
    padding: 05px;
}

.footer_quick_link ul li a{
    all: unset;
    color: white;
    cursor: pointer;
}

.footer_quick_link h2{
    color: white;
    padding-bottom: 20px;

    font-weight: 500;
}
@media screen and (max-width:765px) {
    .contact_project_conatiner h2{
        font-size: 24px;
    }

    .footer_heading h2{
        font-size: 28px;
    }

    .footer_company_name span img{
        padding: 60px 0px;
    }

    .email_input_wrapper{
        display: flex;
        justify-content: space-between;
    }

    .form_input_feild_wrapper{
        max-width: 100%;
    }

    #contact_project_section{
        padding: 60px 20px  ;
    }

    .form_link_main{
        grid-template-columns: repeat(1, 1fr);
    }

    .footer_quick_link{
        margin-top: 40px;
    }
}