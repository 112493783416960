html {
    scroll-behavior: smooth;
  }
  

p,h2,h3,h4,h5,h6,ul,li,a{
    font-family: 'poppins', sans-serif;
}

.portfolio-card-wrapper .image-container img{
    border-radius: 05px;
    transition: all 0.3s ease-in-out;
    object-fit: cover;
    }

    .portfolio-card-wrapper .image-container img:hover{
        scale: 1.1;
        cursor: pointer;
        border-radius: 5px;
        box-sizing: border-box;
    }

    .main-card-container{
        margin: 20px;
    }
    
    .flex_body_wrapper > .section-heading{
        margin: 20px 0px;
        font-family: 'poppins';
        font-size: 62px;
        font-weight: 600;
        text-align: center;
        padding-top: 40px;
    }

    .main-card-wrapper{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
        place-items: center;
    }

    .portfolio-card-wrapper{
        width: 100%;
    }

    .author-info .card-title {
        font-family: "poppins", serif;
        font-weight: 400;
        font-size: 24px;
        text-align: left;
    }

    .image-wrap:hover + .preview-button{
        display: block;
        background-color: red;
    }

    .show-on-hover{
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: -05px;
        left: 0px;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0,0,0,0.2) 40%, rgba(0, 0, 0, 0) 100%);
        border: 5px;
        box-sizing: border-box;
        padding: 1em 1.2em;
        will-change: display;
        border-radius: 05px;
        cursor: pointer;
        overflow: hidden;
    }

    .figure-rollover {
        display: block;
        position: relative;
        transition: all 0.3s;
        margin-bottom: 10px;
        overflow: hidden;
    }

    .figure-button{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .figure-button button{
        all:unset;
        color: black;
        background-color: white;
        padding: 0.5em 1.5em;
        font-size: 18px;
        border-radius:05px ;
        font-family: 'poppins';
        cursor: pointer;
    }

    .figure-information .figure-name{
        color: white;
        font-weight: 400;
        font-size: 1.2rem;
    }

    .author-image img{
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 100%;
    }

    .author-info{
        display: flex;
        gap: 10px;
        flex-direction: column;
    }

    .project_description{
        padding: 0px 0px 40px 0px;
    }

    .featured-project-heading{
        display: flex;
        justify-content: space-between;
        padding: 20px 0px;
        font-size: 24px;
    }   

    .project-info-main{
        display: flex;
        gap: 20px;
        flex-direction: column;
        padding: 20px 0px;
        position: relative;
        border-top: 0.6px solid black;
    }
    .work-wrapper h3{
        font-size: 14px;
        font-weight: 500;
    }

    .feature-project-container .image-container img{
        width: 100%;
        height: 100%;
    }

    .project_feture_image_container{
        padding: 40px 0px;
    }

    .main-card-wrapper{
        overflow: hidden;
    }

    @media screen and (max-width:765px) {
        .main-card-wrapper{
            grid-template-columns: repeat(1,1fr);
        }

        .flex_body_wrapper > .section-heading{
         font-size: 42px;   
        }

        .header-main-heading h1{
            font-size: 32px;
        }

        .portfolio-card-wrapper .image-container img{
            width: 100%;
            height: auto;
        }
        .react-loading-skeleton{
            width: 100%;
            height: 400px;
        }

        .project_feture_image_container img{
            height: 400px;
        }
    }

    @media screen and (min-width:765px)and (max-width:1100px){
        .portfolio-card-wrapper .image-container img{
            width: 100%;
            height: auto;
        }
        .react-loading-skeleton{
            width: 100%;
            height: auto;
        }
    }

    @media screen and (min-width:1200px) {
        .portfolio-card-wrapper .image-container img{
            width: 100%;
            height: auto;
        }

        .react-loading-skeleton{
            width: 100%;
            height: auto;
        }

       
    }

    @media screen and (min-width:1600px) {
        .main-card-wrapper{
            grid-template-columns:repeat(3,1fr);
        }
    }