#module_id_1 input,.textarea_form textarea{
    all: unset;
    padding: 20px 05px;
    width: 100%;
}

.form_fields_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 20px;
    gap: 20px;
  }

  #module_id_1 input,#module_id_1 textarea{
    border-bottom: 0.6px solid black;
  }

  .main-form-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0px 80px;
  }

  #form_module_1_submit{
    margin-top: 20px;
  }

  #form_module_1_submit button{
    all: unset;
    padding: 10px 30px;
    border-radius: 05px;
    background-color: #f48864;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }

  .contact_us_info_wrapper h2{
font-size: 105px;
text-transform: uppercase;
  }

  .contact_us_info_wrapper p{
    font-size: 24px;
  }

  .submission_succes{
  padding: 10px 10px;
  background: lightgreen;
  margin-top: 20px;
  border-radius: 05px;
  word-wrap: break-word;
  width: 100%;
  }

  .submission_error{
    padding: 10px 10px;
    background: rgb(213, 4, 4);
    margin-top: 20px;
    border-radius: 05px;
    word-wrap: break-word;
    width: 100%;
  }

  #module_id_1 input:focus,#module_id_1 textarea:focus{
    border-bottom: 1px solid #f48864;
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width:765px) {
    .contact_us_info_wrapper h2{
      font-size: 42px;
    }

    .form_fields_grid{
      display: block;
    }

    .main-form-wrapper{
      margin: 0px;
      display: block;
    }

    #module_id_1 input, #module_id_1, .textarea_form{
      width: 100%;
    }

    .form_fields_grid{
      padding-bottom: 0px;
    }
  }