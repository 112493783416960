html{
    scroll-behavior: smooth;
}

#website-nav ul li {
  list-style: none;
}

.main-header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 40px 40px 0px 40px;
  align-items: center;
}

#website-nav ul {
  display: flex;
  gap: 20px;
  cursor: pointer;
}

#website-nav ul li {
  position: relative;
}

#website-nav ul li:hover:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 0;
  border-bottom: 2px solid #f48864;
  animation: border_anim 0.3s linear forwards;
}

#website-nav ul li:hover {
  transition: border 0.8s ease-in-out;
}

@keyframes border_anim {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.logo-container h2 a {
  all: unset;
  text-transform: uppercase;    
  cursor: pointer;
}


/* Mobile Menu*/

.mobile-menu-toggle{
    display: none;
}

.mobile-menu-open[aria-hidden="true"],
.mobile-menu-close[aria-hidden="true"] {
  display: none;
}
.mobile-menu-open[aria-hidden="false"],
.mobile-menu-close[aria-hidden="false"] {
  display: block;
}

@media screen and (max-width: 765px) {
    .flex-nav-wrapper {
      position: fixed;
      top: 80px;
      left: 0px;
      overflow: scroll;
      width: 100%;
      padding: 40px;
      background: white;
      height: 100vh;
      z-index: 100;
      display: none;
    }
  
    #website-nav ul{
      flex-direction: column;
    }
  
    #website-nav ul{
      width: 80%;
    }
  
    #website-nav ul li {
      border-bottom: 1px solid black;
    }
  
    #website-nav ul li:hover:before {
      all: unset;
    }
  
    .mobile-menu-toggle{
      display: block;
    }
  }

  #website-nav ul a{
    all: unset;
  }