.flex_wrapper_banner{
    padding: 80px 0px 80px 0px;
}

.upper_banner_heading h1{
    text-align: center;
}

.upper_banner_heading h1{
    font-size: 150px;
    font-family: poppins;
    text-transform: uppercase;
    padding: 0px 0px 20px 0px;
    }

    .company_information p {
        text-align: center;
        font-size: 42px;
        padding: 30px 0px;
    }

    @media screen and (max-width:765px) {
        .flex_wrapper_banner{
            padding: 40px 20px 40px 20px;
        }

        .upper_banner_heading h1{
            font-size: 52px;
        }

        .company_information p{
            font-size: 18px;
        }
    }

    @media screen and (min-width:765px) and (max-width:1200px){
        .upper_banner_heading h1{
            font-size: 130px;
        } 
    }