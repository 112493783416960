.parent_container_carousel{
display: flex;
gap: 20px;
overflow-x: hidden;
justify-content: center;
}

.parent_container_carousel .listed_carousel_children img{
    object-fit: cover;
}

.hover_container{
    width: 200px;
    transition: width 0.5s ease-in-out ;
}

.listed_carousel_children {
    position: relative;
    overflow: hidden; 
  }

.hover_container:hover{
  width: 500px;
  cursor: pointer;
}

.hover_container_grid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 300px;
    background: rgba(0, 0, 0, 0.2); 
    opacity: 0; 
    transition: opacity 0.3s ease-in-out;
  }

  .listed_carousel_children:hover .hover_container_grid {
    opacity: 1;
  }

  .banner_link_button{
    all: unset;
    padding: 05px 20px;
    background-color: white;
    cursor: pointer;
    border-radius: 05px;
  }

  .banner_link{
    all: unset;
    cursor: pointer;
  }
  

@media screen and (max-width:765px) {
.hover_container:hover {
        width: 10000px;
    }
}